import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Typeahead } from 'react-bootstrap-typeahead';
import { getDgos, updateSdp, removeSdp, getSdps } from '../../api/sdps';
import { submit, submitPreswitchFUll } from '../../api/mig6'
import 'bootstrap/js/dist/modal'
import moment from 'moment-timezone'
import LocalizedStrings from '../Localization';
import Loader from '../Loader';
import NumberInput from '../../components/NumberInput';
import DayPickerInput from 'react-day-picker/DayPickerInput'
import MomentLocaleUtils from 'react-day-picker/moment';

class EditSdp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dgos: [],
      sdps: this.props.sdps,
      selectedEan: {},
      errorPowers: [],
      editing: false,
      updatingPreswitch: false,
      updatingPreswitchFull: false,
      update: undefined,
      isModalVisible: false,
      sdpToRemove: null,
      isResultModalVisible: false,
      resultMessage: ''
    }


    this.getDgosList = this.getDgosList.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
    this.onUpdateSdp = this.onUpdateSdp.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
    this.removeSwitchInfo = this.removeSwitchInfo.bind(this);
    this.selectionChange = this.selectionChange.bind(this);
    this.updatePreswitchLight = this.updatePreswitchLight.bind(this);
    this.onCheckboxChange = this.onCheckboxChange.bind(this);
    this.updatePreswitchFull = this.updatePreswitchFull.bind(this);
    this.handleDayChange = this.handleDayChange.bind(this);
    this.onSwitchTypeChange = this.onSwitchTypeChange.bind(this);
    this.onSwitchStatusChange = this.onSwitchStatusChange.bind(this);
    this.onVatPercChange = this.onVatPercChange.bind(this);


    this.strings = LocalizedStrings({
      en: {
        errorRequired: "Missing required field",
        errorVolumeBiggerCapacity: "Volume should not be bigger than power capacity",
        errorPowerCapacity: "Power capacity should not be bellow 1",
        errorPowerVolume: "Power capacity and volume should not be over 999 please contact sales for this to be possible.",
        errorMissingPowerValues: "Power and volume must be filled out to save...",
        preswitchButton: "Preswitch Light",
        preswitchFulllButton: "Preswitch Full",
        removeButton: "Remove",
        confirmDelete: 'Are you sure you want to delete installation',
        forCompany: 'for',
        deleteWarning: 'This action cannot be undone.',
        delete: 'Delete',
        close: 'Close',
        serviceComponent: "Service Component",
        updating: "Updating....",
        errors: "These errors occurred:",
        serviceConfiguration: "Service Configuration",
        error: "Error while updating the SDP information. Please try again.",
        sdpUpdated: "SDP information was successfully updated!",
        preswitchFullSuccessMessage: "Successfully send request to update SDP, we will let you know about status in shortly, Thank you!",
        notAvailable: "Portal was not available. Please try again later...",
        sdp: "SDP Information",
        loading: "Loading EANs...",
        ean: "EAN",
        notRecognized: "EAN not recognized",
        siteReference: "Site Reference",
        legalForm: "Legal form",
        preswtichDate: "Latest preswitch",
        contactEmail: "Contact email",
        technology: "Technology",
        wrongDirectionMessage: "Update has not been done.",
        wrongDirectionMessage1: "The current service component of this SDP is",
        wrongDirectionMessage2: "The required service component for",
        wrongDirectionMessage3: "is not available.",
        wrongDirectionMessage4: "To solve this configuration problem, contact the “DGO”.",
        noSdp: "The company doesn't have any SDPs.",
        addSdp: "Add new SDP",
        loadingDgos: "Loading DGOs...",
        dgo: "DGO",
        errorCode: "Error. Please contact Yuso technical support",
        type: "Type",
        tariff: "Tariff type",
        vreg: "VREG ID",
        comma: "comma separated",
        voltage: "Voltage level",
        streetAddress: "Street address",
        streetNumber: "Street number",
        zipCode: "Zip code",
        city: "City",
        country: "Country",
        belgium: "Belgium",
        theNetherlands: "The Netherlands",
        power: "Power capacity in kW",
        volume: "Volume in MWh",
        vatPerc: "Vat Percentage",
        vatPercStartDate: "Vat start date",
        vatPercAlert: "Can you confirm 6% VAT is applicable for this SDP?",
        dutiesExempt: "Exemption Excise",
        exciseExemptStartDate: "Exemption excise start date",
        for: "For",
        cancel: "Cancel",
        save: "Save",
        errorMinLength: "The EAN number must contain 18 digits.",
        edit: "Edit",
        eocDate: "End Of Contract",
        switchDate: "Switch Date",
        switchStatus: "Switch Status",
        switchType: "Switch Type",
        switchRequestDate: "Switch Request Date",
        removeSwitchInfo: "Remove switch info",
        'power_hydro-electric head installations': "Hydro Power",
        power_other: "Other Power",
        power_solar: "Solar Power",
        power_thermal: "Thermal Power",
        power_wind: "Wind Power",
        confirmRemoval: "Are you sure you want to remove this selected",
        confirm: "Confirm"
      },
      nl: {
        errorRequired: "Ontbrekende vereiste veld",
        errorVolumeBiggerCapacity: "Het volume mag niet groter zijn dan de vermogenscapaciteit",
        errorPowerCapacity: "Het vermogen mag niet lager zijn dan 1",
        errorPowerVolume: "Het vermogen en volume mogen niet groter zijn dan 999. Neem contact op met de afdeling verkoop om dit mogelijk te maken",
        errorMissingPowerValues: "Vermogen en volume moeten worden ingevuld om op te slaan...",
        preswitchButton: "Preswitch Light",
        preswitchFulllButton: "Preswitch Full",
        updating: "Updaten....",
        errorCode: "Fout neem contact op met de technische ondersteuning van Yuso",
        notAvailable: "Portal was niet beschikbaar. Probeer het later nog eens...",
        serviceComponent: "Service Component",
        errors: "Deze fouten zijn opgetreden:",
        siteReference: "Site Referentie",
        legalForm: "Rechtsvorm",
        contactEmail: "Contact email",
        notRecognized: "EAN niet herkend",
        preswtichDate: "Laatste preswitch",
        technology: "Technologie",
        wrongDirectionMessage: "Update is niet gedaan.",
        wrongDirectionMessage1: "De huidige service component voor dit punt is",
        wrongDirectionMessage2: " De gewenste service component voor",
        wrongDirectionMessage3: "is niet beschikbaar.",
        wrongDirectionMessage4: "Contacteer “DGO” om dit probleem op te lossen.",
        serviceConfiguration: "Service Configuratie",
        error: "Fout tijdens het updaten van de SDP-informatie. Probeer het opnieuw.",
        sdpUpdated: "SDP-informatie is succesvol bijgewerkt!",
        sdp: "Bewerk SDP-gegevens",
        loading: "EANs laden...",
        ean: "EAN",
        noSdp: "Het bedrijf heeft geen SDPs.",
        addSdp: "Voeg nieuwe SDP toe",
        loadingDgos: "DGO's laden...",
        dgo: "DGO",
        type: "Type",
        tariff: "Tarieftype",
        vreg: "VREG ID",
        comma: "kommagescheiden",
        voltage: "Spanningsniveau",
        streetAddress: "Straat",
        streetNumber: "Huisnummer",
        zipCode: "Postcode",
        city: "Stad",
        country: "Land",
        belgium: "België",
        theNetherlands: "Nederland",
        power: "Vermogen in kW",
        volume: "Volume in MWh",
        vatPerc: "BTW-percentage",
        vatPercAlert: "Kunt u bevestigen dat 6% btw van toepassing is op dit SDP?",
        vatPercStartDate: "Begindatum btw",
        dutiesExempt: "Vrijstelling accijns",
        for: "Voor",
        update: "Bijwerken",
        errorMinLength: "Het EAN-nummer moet uit 18 cijfers bestaan.",
        eocDate: "einde contract",
        switchDate: "Switch Date",
        switchStatus: "Switch Status",
        switchType: "Switch Type",
        switchRequestDate: "Switch Request Date",
        removeSwitchInfo: "Remove switch info",
        confirmRemoval: "Weet je zeker dat je deze selectie wilt verwijderen",
        confirm: "Confirm"
      }
    }, this.props.lang);
  }


  componentDidMount() {
    this.getDgosList();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.sdps !== this.props.sdps) {
      this.setState({ sdps: Array.isArray(this.props.sdps) ? this.props.sdps : [] }); // Ensure sdps is always an array
    }
  }

  getDgosList() {
    getDgos((err, res) => {
      if (err)
        return window.reportError.send(err);

      this.setState({
        dgos: res.filter(x => x.country === this.state.selectedEan.addressCountry).map(x => x.dgo)
      }, () => {
        if (this.state.selectedEan.dgo) {

          this.setState({
            ...this.state.selectedEan,
            dgo: this.state.dgos.find(dgo => dgo.replace(/[^a-z0-9]/gi, '').toLowerCase() === this.state.selectedEan.dgo.replace(/[^a-z0-9]/gi, '').toLowerCase()) || ''
          })
        }
      });
    })
  }

  onFieldChange(e) {

    if (this.state.selectedEan.ean) {
      let field = {}
      field[e.target.id] = e.target.type === 'checkbox' ? e.target.checked : e.target.value

      if (e.target.id === 'ean') {
        e.target.value = 'EAN' + e.target.value.replace(/[^\d]/g, '').toUpperCase().trim();
        field[e.target.id] = e.target.value
      }

      this.setState({
        selectedEan: { ...this.state.selectedEan, ...field }
      }, this.getDgosList)
    }
  }

  onSwitchTypeChange(event) {
    const switchType = event.target.value;
    this.setState({
      switchType,
      selectedEan: {
        ...this.state.selectedEan,
        switchType: switchType
      },
    });
  }

  onSwitchStatusChange = (event) => {
    const { value } = event.target;
    this.setState(prevState => ({
      switchStatus: value,
      selectedEan: {
        ...prevState.selectedEan,
        switchStatus: value,
      },
    }));
  };

  onVatPercChange = (event) => {
    const { value } = event.target;
    if (value === "6" && !window.confirm(this.strings.vatPercAlert)) {
      return;
    }
    this.setState(prevState => ({
      VATPerc: value,
      selectedEan: {
        ...prevState.selectedEan,
        VATPerc: value,
      },
    }));
  };



  handleDayChange = (field, selectedDay, modifiers, dayPickerInput) => {
    const formatDate = moment(selectedDay).format('YYYY-MM-DD');
    this.setState(prevState => ({
      selectedEan: { ...prevState.selectedEan, [field]: formatDate }
    }));
  };


  onRemoveSdp = () => {
    if (this.props.selectedCompany) {
      getSdps(this.props.selectedCompany.company_id, (err, res) => {
        if (err) {
          window.reportError.send(err);
          return;
        }

        const combinedSdps = [
          ...(res.sdps_injection_hourly || []),
          ...(res.sdps_offtake_hourly || []),
          ...(res.sdps_production || []),
        ];

        this.setState({
          sdps: combinedSdps,
          sdpToRemove: null,
          selectedEan: {},
        });
      });
    }
  }

  showRemoveModal = (event) => {
    event.preventDefault();
    this.setState({
      isModalVisible: true,
      sdpToRemove: this.state.selectedEan
    });
  };

  handleCancel = () => {
    this.setState({
      isModalVisible: false,
      sdpToRemove: null,
    });
  };

  handleConfirm = () => {
    this.setState({ isModalVisible: false }, () => {
      this.handleRemove(this.state.sdpToRemove);
    });
  };

  handleRemove = (selectedEan) => {
    const { selectedCompany } = this.props;
    if (!selectedCompany || !selectedEan) return;

    const { company_id } = selectedCompany;
    const { installation_id, type } = selectedEan;

    removeSdp(company_id, installation_id, type, (err, res) => {
      if (err) {
        window.reportError.send(err);
        this.setState({
          errors: [this.strings.removeError],
          resultMessage: 'An error occurred while removing the selected contract. Please try again.',
          isResultModalVisible: true
        });
        return;
      }

      if (res.error) {
        this.setState({
          errors: [this.strings.removeError],
          resultMessage: 'An error occurred while removing the selected contract. Please try again.',
          isResultModalVisible: true,
        });
      } else if (res.status === 'OK') {
        this.setState((prevState) => ({
          selectedEan: {},
          sdps: prevState.sdps.filter(sdp => sdp.installation_id !== selectedEan.installation_id),
        }))
        this.setState(() => ({
          resultMessage: 'The sdp was successfully removed',
          isResultModalVisible: true,
        }))
        this.onRemoveSdp();
      }
    });
  }

  closeResultModal = () => {
    this.setState({ isResultModalVisible: false, resultMessage: '' });
  };

  selectionChange(selection) {
    if (selection.length) {

      let selectedEan = selection[0]

      this.setState({ selectedEan: selectedEan }, this.getDgosList)
    }
    else
      this.setState({ selectedEan: {} })
  }


  updatePreswitchLight = (event) => {
    event.preventDefault();
    this.setState({ updatingPreswitch: true }, () => {
      submit(
        this.state.selectedEan.ean,
        "PRESWITCH_UPDATE",
        this.state.selectedEan.type_sdp,
        (err, res) => {
          if (err) {
            this.handleError(err);
          } else {
            this.handleUpdateResponse(res);
          }
        }
      );
    });
  }

  handleUpdateResponse(response) {
    if (response.available) {
      this.updateSelectedEan(response.ean);
    } else {
      this.handleUnavailableResponse(response);
    }
  }

  updateSelectedEan(updatedEan) {
    this.setState({
      selectedEan: { ...this.state.selectedEan, ...updatedEan },
      updatingPreswitch: false,
      update: true
    }, () => {
      if (this.state.selectedEan.wrongDirection) {
        this.showInfo(this.constructWrongDirectionMessage(), 6000);
      } else {
        this.updateSdp();
      }
    });
  }

  constructWrongDirectionMessage() {
    return `${this.strings.wrongDirectionMessage} ${this.strings.wrongDirectionMessage1} ${this.state.selectedEan.curretComponent}. ${this.strings.wrongDirectionMessage2} ${this.state.selectedEan.type_sdp} ${this.strings.wrongDirectionMessage3} ${this.strings.wrongDirectionMessage4}`;
  }

  updateSdp() {
    updateSdp(this.props.selectedCompany.company_id, this.state.selectedEan, (err, res) => {
      if (err) {
        this.handleError(err);
        return;
      }
      if (res.error) {
        this.setState({ errors: [this.strings.error] });
      } else if (res.status === 'OK') {
        this.showInfo(this.strings.sdpUpdated, 3000);
      }
    });
  }

  handleUnavailableResponse(response) {
    let infoMessage = this.strings.notAvailable;
    if (response.recognized === "notRecognized") {
      infoMessage = this.strings.notRecognized;
    } else if (response.error) {
      infoMessage = this.strings.errorCode;
    }
    this.setState({
      preswitch: response,
      updatingPreswitch: false,
      update: undefined,
      info: infoMessage
    }, () => {
      setTimeout(() => {
        this.setState({ info: undefined });
      }, 5000);
    });
  }

  showInfo(message, timeout) {
    this.setState({ info: message }, () => {
      setTimeout(() => {
        this.setState({ info: undefined });
      }, timeout);
    });
  }

  handleError(error) {
    window.reportError.send(error);
    this.setState({ updatingPreswitch: false, error: error.toString() });
  }


  updatePreswitchFull = (event) => {
    event.preventDefault();
    this.setState({ updatingPreswitchFull: true }, () => {
      submitPreswitchFUll(
        this.state.selectedEan.ean,
        "PRESWITCH_FULL_UPDATE",
        this.state.selectedEan.type_sdp,
        this.state.selectedEan.installation_id,
        (err, res) => {
          if (err) {
            this.handleErrorFull(err);
          } else {
            this.handleFullUpdateResponse(res);
          }
        }
      );
    });
  }


  handleFullUpdateResponse(response) {
    if (response.status === "success") {
      this.showInfoFull(response.message, 6000);
      this.setState({ updatingPreswitchFull: false });
    } else {
      this.handleUnavailableFullResponse(response);
    }
  }

  handleUnavailableFullResponse(response) {
    let infoMessage = response.recognized === "notRecognized" ? this.strings.notRecognizedFull : this.strings.notAvailableFull;
    if (response.error) {
      infoMessage = this.strings.errorCodeFull;
    }
    this.setState({
      preswitchFull: response,
      updatingPreswitchFull: false,
      updateFull: undefined,
      infoFull: infoMessage
    }, () => {
      setTimeout(() => {
        this.setState({ infoFull: undefined });
      }, 6000);
    });
  }

  showInfoFull(message, timeout) {
    this.setState({ infoFull: message }, () => {
      setTimeout(() => {
        this.setState({ infoFull: undefined });
      }, timeout);
    });
  }

  handleErrorFull(error) {
    window.reportError.send(error);
    this.setState({ updatingPreswitchFull: false, errorFull: error.toString() });
  }


  onUpdateSdp(e) {
    e.preventDefault()

    this.setState({
      errors: undefined
    })

    let error = [];

    const form = e.target.elements;

    let totalPower = Number(this.state.selectedEan['power_hydro-electric head installations']) + Number(this.state.selectedEan.total_power) + Number(this.state.selectedEan.power_other) + Number(this.state.selectedEan.power_solar) + Number(this.state.selectedEan.power_thermal) + Number(this.state.selectedEan.power_wind)

    // if (!(Number(this.state.selectedEan.power_wind) === 0 && Number(this.state.selectedEan['power_hydro-electric head installations']) === 0 && Number(this.state.selectedEan.power_other) === 0 && Number(this.state.selectedEan.power_solar) === 0 && Number(this.state.selectedEan.power_thermal) !== 0)) {

    //   if (this.state.selectedEan.type_sdp === 'injection' && (Number(this.state.selectedEan.volume) > totalPower)) {
    //     this.state.errorPowers.push(this.strings.errorVolumeBiggerCapacity)

    //   }
    // }

    // if (this.state.selectedEan.type_sdp === 'offtake' && (this.state.selectedEan.volume > this.state.selectedEan.power)) {
    //   this.state.errorPowers.push(this.strings.errorVolumeBiggerCapacity)
    // }

    if (this.state.selectedEan.power < 1 && totalPower < 1) {
      this.state.errorPowers.push(this.strings.errorPowerCapacity)
    }

    if ((this.props.selectedCompany.role === 'partner') && (this.state.selectedEan.volume > 9999 || totalPower > 9999 || this.state.selectedEan.power > 9999)) {
      this.state.errorPowers.push(this.strings.errorPowerVolume)
    }

    if ((this.state.selectedEan.power_other === null || this.state.selectedEan.power_other === "") && (this.state.selectedEan.power_solar === null || this.state.selectedEan.power_solar === "") && (this.state.selectedEan.power_thermal === null || this.state.selectedEan.power_thermal === "") && (this.state.selectedEan.power_wind === null || this.state.selectedEan.power_wind === "") && (this.state.selectedEan.volume === null || this.state.selectedEan.volume === "") && (this.state.selectedEan['power_hydro-electric head installations'] === null || this.state.selectedEan['power_hydro-electric head installations'] === "")) {
      this.state.errorPowers.push(this.strings.errorMissingPowerValues)
    }

    if (this.state.errorPowers.length) {
      setTimeout(() => this.setState({ errorPowers: [] }), 7000)
      return;
    }


    for (let i = 0; i < form.length; i++) {


      if (form[i].required && !form[i].value.trim().length) {
        error.push(`${this.strings.errorRequired} ${form[i].name.split('_').join(' ')}`)
      }
      if (form[i].name === 'ean' && form[i].value.trim() !== '' && form[i].value.trim() !== 'EAN' && form[i].minLength && form[i].value.trim().length < parseInt(form[i].minLength, 10)) {
        error.push(this.strings.errorMinLength)
      }
    }

    if (error.length) {
      this.setState({ errors: error }, () => {
        setTimeout(() => {
          this.setState({ errors: undefined })
        }, 5000);
      })
      return;
    }

    updateSdp(this.props.selectedCompany.company_id, this.state.selectedEan,
      (err, res) => {
        if (err)
          return window.reportError.send(err);

        if (res.error) {
          this.setState({ errors: [this.strings.error] })
        }

        if (res.status === 'OK') {
          this.setState({ update: undefined }, () => {
            this.props.updateSdps()
            setTimeout(() => {
              this.setState({ info: undefined, editing: false })
            }, 5000);
          })
        }
      })
  }


  toggleEdit() {
    this.setState({
      editing: !this.state.editing
    })
  }

  removeSwitchInfo = () => {
    this.setState({
      switchStatus: '',
      selectedEan: {
        ...this.state.selectedEan,
        switchDate: null,
        requestDate: null,
        switchStatus: '',
        switchType: ''
      },
      switchType: '',
    });
  };


  clearEocDate = () => {
    this.setState(prevState => ({
      selectedEan: {
        ...prevState.selectedEan,
        eocDate: null
      }
    }));
  };

  onCheckboxChange(event) {
    const { value, checked } = event.target;
    const { selectedEan } = this.state;

    let selectedTechnologies = selectedEan.technology ? selectedEan.technology.split(',').map(item => item.trim()) : [];

    if (checked) {
      selectedTechnologies.push(value);
    } else {
      selectedTechnologies = selectedTechnologies.filter(tech => tech !== value);
      selectedEan[`power_${value.toLowerCase()}`] = '';
    }

    selectedEan.technology = selectedTechnologies.join(', ');

    this.setState({ selectedEan });
  }


  render() {
    const { sdps } = this.state;
    const uniqueTechnologies = this.state.selectedEan.type_sdp === "injection" ?
      new Set(this.state.selectedEan.technology ? this.state.selectedEan.technology.split(',').map(item => item.trim()) : []) :
      new Set();
    return (
      <div className="card mb-4">
        <div className="card-header">
          {this.strings.sdp}
        </div>
        <div className="card-body">
          {
            !this.props.sdps ?
              <Loader inline="true">{this.strings.loading}</Loader>
              :
              <div>
                {
                  !this.props.sdps.length ?
                    <p className="alert alert-info">
                      <i className="fas fa-info-circle mr-2"></i> {this.strings.noSdp}
                      <br />
                      <Link to="/services/onboarding" className="btn btn-primary mt-3" style={{ textDecoration: 'none' }}>{this.strings.addSdp}</Link>
                    </p>
                    :
                    <form onSubmit={this.onUpdateSdp} noValidate>
                      <div className="form-group row">
                        <label htmlFor="editSdpInformationEAN" className="col-sm-3 col-form-label">SDP</label>
                        <div className="col-sm-9 special-select">
                          <Typeahead
                            key={this.props.sdps.length} // Or some other unique aspect of sdps
                            id="editSdpInformationEAN"
                            options={sdps}
                            labelKey={x => `${x.ean} (${x.installation_id})`}
                            onChange={this.selectionChange}
                            filterBy={!this.state.selectedEan.ean ? undefined : () => true}
                            selected={this.state.selectedEan.ean ? [this.state.selectedEan] : []}
                            clearButton
                          />
                        </div>
                      </div>

                      {
                        this.state.selectedEan.ean ?
                          this.state.editing && !(this.props.selectedCompany.role === "partner" && this.state.selectedEan.signatureDate !== null) ?
                            <div>
                              {
                                this.props.selectedCompany.role === 'admin' ?
                                  <div className="form-group row">
                                    <label htmlFor="ean" className="col-sm-3 col-form-label">{this.strings.ean}</label>
                                    <div className="col-sm-9">
                                      <input type="text" className="form-control" id="ean" name="ean" value={this.state.selectedEan.ean || ''} onChange={this.onFieldChange} placeholder={this.strings.ean} aria-label="EAN number" maxLength="21" minLength="21" />
                                    </div>
                                  </div>
                                  : null
                              }

                              <div className="form-group row required required-select">
                                <label htmlFor="dgo" className="col-sm-3 col-form-label">{this.strings.dgo}</label>
                                <div className="col-sm-9">
                                  {
                                    this.state.dgos.length ?
                                      <select className="form-control" id="dgo" name="DGO" value={this.state.selectedEan.dgo} onChange={this.onFieldChange} required>
                                        <option value="" disabled>{this.strings.dgo}</option>
                                        {
                                          this.state.dgos.map(dgo => {
                                            return (
                                              <option key={dgo} value={dgo}>{dgo}</option>
                                            )
                                          })
                                        }
                                      </select> :
                                      <div className="d-flex align-items-center h-100">
                                        <Loader inline="true">{this.strings.loadingDgos}</Loader>
                                      </div>
                                  }
                                </div>
                              </div>

                              <div className="form-group row">
                                <label htmlFor="dgo_tariff_id" className="col-sm-3 col-form-label">{this.strings.serviceComponent}</label>
                                <div className="col-sm-9">
                                  <input type="text" className="form-control" id="service_component" name="DGO_tariff_id" value={this.state.selectedEan.service_component || ''} onChange={this.onFieldChange} />
                                </div>
                              </div>

                              <div className="form-group row">
                                <label htmlFor="voltageLevel" className="col-sm-3 col-form-label">{this.strings.serviceConfiguration}</label>
                                <div className="col-sm-9">
                                  <input type="text" className="form-control" id="service_config" name="voltage_level" value={this.state.selectedEan.service_config || ''} onChange={this.onFieldChange} />
                                </div>
                              </div>

                              {this.state.selectedEan.type_sdp === "injection" && (
                                <div className="form-group row required">
                                  <label htmlFor="technology" className="col-sm-3 col-form-label">{this.strings.technology}</label>
                                  <div className="col-sm-9 mt-2" style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div className="form-check" style={{ display: 'flex', flexWrap: 'wrap' }}>
                                      {["Solar", "Wind", "Thermal", "Hydro-electric head installations", "Other"].map((technology, index) => (
                                        <label className="form-check-label mr-2" key={index} style={{ marginRight: '10px', marginBottom: '5px' }}>
                                          <input
                                            type="checkbox"
                                            name="technology"
                                            value={technology}
                                            checked={this.state.selectedEan.technology && this.state.selectedEan.technology.split(',').map(item => item.trim()).includes(technology)}
                                            onChange={this.onCheckboxChange}
                                            required
                                          />
                                          {technology}
                                        </label>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              )}

                              {
                                this.state.selectedEan.type_sdp === 'injection' ?
                                  <div className="form-group row">
                                    <label htmlFor="vregProducer" className="col-sm-3 col-form-label">{this.strings.vreg}</label>
                                    <div className="col-sm-9">
                                      <input type="text" className="form-control" id="vregProducer" name="VREG_ID" value={this.state.selectedEan.vregProducer || ''} onChange={this.onFieldChange} placeholder={this.strings.comma} />
                                    </div>
                                  </div>
                                  : null
                              }

                              <div className="form-group row required">
                                <label htmlFor="addressStreet" className="col-sm-3 col-form-label">{this.strings.streetAddress}</label>
                                <div className="col-sm-9">
                                  <input type="text" className="form-control" id="addressStreet" name="address_street" value={this.state.selectedEan.addressStreet || ''} onChange={this.onFieldChange} required />
                                </div>
                              </div>

                              <div className="form-group row required">
                                <label htmlFor="addressNumber" className="col-sm-3 col-form-label">{this.strings.streetNumber}</label>
                                <div className="col-sm-9">
                                  <input type="text" className="form-control" id="addressNumber" name="address_number" value={this.state.selectedEan.addressNumber || ''} onChange={this.onFieldChange} required />
                                </div>
                              </div>

                              <div className="form-group row required">
                                <label htmlFor="addressZip" className="col-sm-3 col-form-label">{this.strings.zipCode}</label>
                                <div className="col-sm-9">
                                  <NumberInput type="number" className="form-control" id="addressZip" name="address_zip" value={this.state.selectedEan.addressZip || ''} onChange={this.onFieldChange} required />
                                </div>
                              </div>

                              <div className="form-group row required">
                                <label htmlFor="addressCity" className="col-sm-3 col-form-label">{this.strings.city}</label>
                                <div className="col-sm-9">
                                  <input type="text" className="form-control" id="addressCity" name="address_city" value={this.state.selectedEan.addressCity || ''} onChange={this.onFieldChange} required />
                                </div>
                              </div>

                              <div className="form-group row required">

                                <label htmlFor="addressCountry" className="col-sm-3 col-form-label">{this.strings.country}</label>
                                <div className="col-sm-9">
                                  <select className="form-control" name="address_country" id="addressCountry" value={this.state.selectedEan.addressCountry || ''} onChange={this.onFieldChange} required>
                                    <option disabled hidden value="">
                                      {this.strings.selectCountry}
                                    </option>
                                    <option value="Belgium">{this.strings.belgium}</option>
                                    <option value="The Netherlands">{this.strings.theNetherlands}</option>
                                  </select>
                                </div>
                              </div>

                              {this.state.selectedEan.type_sdp === 'injection' ?
                                Array.from(uniqueTechnologies).map((technology, index) => (
                                  <div className="form-group row" key={index}>
                                    <label htmlFor={`${technology.replace(/\s+/g, '-')}_power`} className="col-sm-3 col-form-label">
                                      {`${technology} Power`}
                                    </label>
                                    <div className="col-sm-9">
                                      <NumberInput
                                        type="number"
                                        className="form-control"
                                        id={`power_${technology.toLowerCase().replace(/\s+/g, '-')}`}
                                        name={`power_${technology.toLowerCase()}`}
                                        value={this.state.selectedEan[`power_${technology.toLowerCase()}`] || ''}
                                        onChange={this.onFieldChange}
                                      />
                                    </div>
                                  </div>
                                ))
                                :
                                <div className="form-group row">
                                  <label htmlFor="power" className="col-sm-3 col-form-label">{this.strings.power}{this.state.selectedEan.type_sdp === 'injection' ? 'p' : ''}</label>
                                  <div className="col-sm-9">
                                    <NumberInput type="number" className="form-control" id="power" name="power" value={this.state.selectedEan.power || ''} onChange={this.onFieldChange} />
                                  </div>
                                </div>
                              }
                              <div className="form-group row">
                                <label htmlFor="vatPerc" className="col-sm-3 col-form-label">{this.strings.vatPerc}</label>
                                <div className="col-sm-9 special-select">
                                  <select
                                    className="form-control"
                                    id="VATPerc"
                                    name="vatPerc"
                                    value={this.state.VATPerc}
                                    onChange={this.onVatPercChange}
                                  >
                                    <option value=""></option>

                                    <option value="6">6</option>
                                    <option value="21">21</option>
                                  </select>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label htmlFor="vatPercStartDate" className="col-sm-3 col-form-label">{this.strings.vatPercStartDate}</label>
                                <div className="col-sm-9">
                                  <DayPickerInput
                                    value={this.state.selectedEan.vatPercStartDate}
                                    format="DD-MM-YYYY"
                                    placeholder="DD-MM-YYYY"
                                    formatDate={(date, format) => moment(date).format(format)}
                                    inputProps={{ className: 'form-control', readOnly: true, id: 'vatPercStartDate', name: 'vatPercStartDate' }}
                                    dayPickerProps={{
                                      selectedDays: this.state.selectedEan.vatPercStartDate,
                                      localeUtils: MomentLocaleUtils,
                                      locale: this.props.lang
                                    }}
                                    onDayChange={(selectedDay, modifiers, dayPickerInput) =>
                                      this.handleDayChange('vatPercStartDate', selectedDay, modifiers, dayPickerInput)}
                                  />
                                </div>
                              </div>
                              {this.state.selectedEan.type_sdp === 'offtake' ? <> <div className="form-group row required">
                                <label htmlFor="dutiesExempt" className="col-sm-3 col-form-label">{this.strings.dutiesExempt}</label>
                                <div className="col-sm-9">
                                  <input type="text" className="form-control" id="dutiesExempt" name="dutiesExempt" value={this.state.selectedEan.dutiesExempt || ''} onChange={this.onFieldChange} />
                                </div>
                              </div> <div className="form-group row">
                                  <label htmlFor="exciseExemptStartDate" className="col-sm-3 col-form-label">{this.strings.exciseExemptStartDate}</label>
                                  <div className="col-sm-9">
                                    <DayPickerInput
                                      value={this.state.selectedEan.exciseExemptStartDate}
                                      format="DD-MM-YYYY"
                                      placeholder="DD-MM-YYYY"
                                      formatDate={(date, format) => moment(date).format(format)}
                                      inputProps={{ className: 'form-control', readOnly: true, id: 'exciseExemptStartDate', name: 'exciseExemptStartDate' }}
                                      dayPickerProps={{
                                        selectedDays: this.state.selectedEan.exciseExemptStartDate,
                                        localeUtils: MomentLocaleUtils,
                                        locale: this.props.lang
                                      }}
                                      onDayChange={(selectedDay, modifiers, dayPickerInput) =>
                                        this.handleDayChange('exciseExemptStartDate', selectedDay, modifiers, dayPickerInput)}
                                    />
                                  </div>
                                </div></> : null}
                              <div className="form-group row">
                                <label htmlFor="volume" className="col-sm-3 col-form-label">{this.strings.volume}</label>
                                <div className="col-sm-9">
                                  <NumberInput type="number" className="form-control" id="volume" name="volume" value={this.state.selectedEan.volume || ''} onChange={this.onFieldChange} />
                                </div>
                              </div>
                              <div className="form-group row">
                                <label htmlFor="volume" className="col-sm-3 col-form-label">{this.strings.siteReference}</label>
                                <div className="col-sm-9">
                                  <input type="text" className="form-control" id="siteReference" name="siteReference" value={this.state.selectedEan.siteReference || ''} onChange={this.onFieldChange} />
                                </div>
                              </div>

                              <div className="form-group row">
                                <label htmlFor="eocDate" className="col-sm-3 col-form-label">{this.strings.eocDate}</label>
                                <div className="col-sm-9">
                                  <DayPickerInput
                                    value={this.state.selectedEan.eocDate}
                                    format="DD-MM-YYYY"
                                    placeholder="DD-MM-YYYY"
                                    formatDate={(date, format) => moment(date).format(format)}
                                    inputProps={{ className: 'form-control', readOnly: true, id: 'eocDate', name: 'eocDate' }}
                                    dayPickerProps={{
                                      selectedDays: this.state.selectedEan.eocDate,
                                      localeUtils: MomentLocaleUtils,
                                      locale: this.props.lang
                                    }}
                                    onDayChange={(selectedDay, modifiers, dayPickerInput) =>
                                      this.handleDayChange('eocDate', selectedDay, modifiers, dayPickerInput)}
                                  />
                                  <button type="button" className="btn btn-danger mr-2" style={{ marginTop: '1rem', fontSize: '12px' }} onClick={this.clearEocDate}>Remove EOC Date</button>
                                </div>
                              </div>


                              <div className="form-group row">
                                <label htmlFor="switchStatus" className="col-sm-3 col-form-label">{this.strings.switchStatus}</label>
                                <div className="col-sm-9 special-select">
                                  <select
                                    className="form-control"
                                    name="switchStatus"
                                    value={this.state.switchStatus}
                                    onChange={this.onSwitchStatusChange}
                                  >
                                    <option value=""></option>

                                    <option value="39">Accepted - 39</option>
                                    <option value="41">Rejected - 41</option>
                                  </select>
                                </div>
                              </div>


                              <div className="form-group row">
                                <label htmlFor="switchDate" className="col-sm-3 col-form-label">{this.strings.switchDate}</label>
                                <div className="col-sm-9">
                                  <DayPickerInput
                                    value={this.state.selectedEan.switchDate}
                                    format="DD-MM-YYYY"
                                    placeholder="DD-MM-YYYY"
                                    formatDate={(date, format) => moment(date).format(format)}
                                    inputProps={{ className: 'form-control', readOnly: 'readonly', id: 'switchDate', name: 'switchDate' }}
                                    dayPickerProps={{
                                      selectedDays: this.state.selectedEan.switchDate,
                                      localeUtils: MomentLocaleUtils,
                                      locale: this.props.lang
                                    }}
                                    onDayChange={(selectedDay, modifiers, dayPickerInput) =>
                                      this.handleDayChange('switchDate', selectedDay, modifiers, dayPickerInput)}
                                  />
                                </div>
                              </div>

                              <div className="form-group row">
                                <label htmlFor="requestDate" className="col-sm-3 col-form-label">{this.strings.switchRequestDate}</label>
                                <div className="col-sm-9">
                                  <DayPickerInput
                                    value={this.state.selectedEan.requestDate}
                                    format="DD-MM-YYYY"
                                    placeholder="DD-MM-YYYY"
                                    formatDate={(date, format) => moment(date).format(format)}
                                    inputProps={{ className: 'form-control', readOnly: 'readonly', id: 'requestDate', name: 'requestDate' }}
                                    dayPickerProps={{
                                      selectedDays: this.state.selectedEan.requestDate,
                                      localeUtils: MomentLocaleUtils,
                                      locale: this.props.lang
                                    }}
                                    onDayChange={(selectedDay, modifiers, dayPickerInput) =>
                                      this.handleDayChange('requestDate', selectedDay, modifiers, dayPickerInput)}
                                  />
                                </div>
                              </div>
                              <div className="form-group row">
                                <label htmlFor="SdpSwitchType" className="col-sm-3 col-form-label">{this.strings.switchType}</label>
                                <div className="col-sm-9 special-select">

                                  <select className="form-control" name="switchType" value={this.state.switchType} onChange={this.onSwitchTypeChange}>
                                    <option value=""></option>
                                    <>
                                      <option value="Supplier Switch">Supplier Switch</option>
                                      <option value="Move-in">Move-in </option>
                                      <option value="Initiate Local Production">Initiate Local Production (ILP)</option>
                                    </>
                                  </select>
                                </div>
                              </div>
                              <div className="clearfix mb-3 text right">
                                <button type="cancel" className="btn btn-secondary mr-2" onClick={this.toggleEdit}>{this.strings.cancel}</button>
                                <button type="button" className="btn btn-danger mr-2" onClick={this.removeSwitchInfo}>{this.strings.removeSwitchInfo}</button>
                                <button type="submit" className="btn btn-primary ">{this.strings.save}</button>
                              </div>
                              {
                                <p className={'alert alert-warning ' + (this.state.errorPowers.length ? 'd-block' : 'd-none')}>
                                  <i className="fas fa-exclamation-triangle mr-2"></i> {`${this.state.errorPowers.join(". ")}.`}
                                </p>
                              }
                            </div>
                            :
                            <div>
                              <table className="table table-striped">
                                <tbody>
                                  <tr>
                                    <td style={{ width: '37.5%' }}>{this.strings.ean}</td>
                                    <td><strong>{this.state.selectedEan.ean}</strong></td>
                                  </tr>
                                  <tr>
                                    <td>{this.strings.dgo}</td>
                                    <td><strong>{this.state.selectedEan.dgo}</strong></td>
                                  </tr>
                                  <tr>
                                    <td>{this.strings.serviceComponent}</td>
                                    <td><strong>{this.state.selectedEan.service_component !== null ? this.state.selectedEan.service_component : 'N/A'}</strong></td>
                                  </tr>
                                  <tr>
                                    <td>{this.strings.serviceConfiguration}</td>
                                    <td><strong>{this.state.selectedEan.service_config !== null ? this.state.selectedEan.service_config : 'N/A'}</strong></td>
                                  </tr>
                                  {this.state.selectedEan.type_sdp === 'injection' ?
                                    <tr>
                                      <td>{this.strings.technology}</td>
                                      <td><strong>{this.state.selectedEan.technology}</strong></td>
                                    </tr>
                                    : null
                                  }
                                  <tr>
                                    <td>{this.strings.vreg}</td>
                                    <td><strong>{this.state.selectedEan.vregProducer}</strong></td>
                                  </tr>
                                  <tr>
                                    <td>{this.strings.streetAddress}</td>
                                    <td><strong>{this.state.selectedEan.addressStreet}</strong></td>
                                  </tr>
                                  <tr>
                                    <td>{this.strings.streetNumber}</td>
                                    <td><strong>{this.state.selectedEan.addressNumber}</strong></td>
                                  </tr>
                                  <tr>
                                    <td>{this.strings.zipCode}</td>
                                    <td><strong>{this.state.selectedEan.addressZip}</strong></td>
                                  </tr>
                                  <tr>
                                    <td>{this.strings.city}</td>
                                    <td><strong>{this.state.selectedEan.addressCity}</strong></td>
                                  </tr>
                                  <tr>
                                    <td>{this.strings.country}</td>
                                    <td><strong>{this.state.selectedEan.addressCountry}</strong></td>
                                  </tr>
                                  {this.state.selectedEan.type_sdp === 'injection' ?
                                    Object.entries(this.state.selectedEan).map(([key, value]) => {
                                      if (key.startsWith("power_") && value !== null && value !== 0 && value !== '') {
                                        return (
                                          <tr key={key}>
                                            <td>{this.strings[key]}</td>
                                            <td><strong>{value}</strong></td>
                                          </tr>
                                        );
                                      }
                                      return null;
                                    })
                                    :
                                    <tr>
                                      <td>{this.strings.power}</td>
                                      <td><strong>{this.state.selectedEan.power}</strong></td>
                                    </tr>
                                  }
                                  <tr>
                                    <td>{this.strings.volume}</td>
                                    <td><strong>{this.state.selectedEan.volume}</strong></td>
                                  </tr>
                                  <tr>
                                    <td>{this.strings.vatPerc}</td>
                                    <td><strong>{this.state.selectedEan.VATPerc}</strong></td>
                                  </tr>
                                  <tr>
                                    <td>{this.strings.vatPercStartDate}</td>
                                    <td><strong>{this.state.selectedEan.vatPercStartDate !== null ? moment(this.state.selectedEan.vatPercStartDate, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY') : 'N/A'}</strong></td>
                                  </tr>
                                  {this.state.selectedEan.type_sdp === 'offtake' ? (
                                    <>
                                      <tr>
                                        <td>{this.strings.dutiesExempt}</td>
                                        <td><strong>{this.state.selectedEan.dutiesExempt}</strong></td>
                                      </tr>
                                      <tr>
                                        <td>{this.strings.exciseExemptStartDate}</td>
                                        <td><strong>{this.state.selectedEan.exciseExemptStartDate !== null ? moment(this.state.selectedEan.exciseExemptStartDate, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY') : 'N/A'}</strong></td>
                                      </tr>
                                    </>
                                  ) : null}
                                  <tr>
                                    <td>{this.strings.siteReference}</td>
                                    <td><strong>{(this.state.selectedEan.siteReference !== "" && this.state.selectedEan.siteReference !== null) ? this.state.selectedEan.siteReference : 'N/A'}</strong></td>
                                  </tr>
                                  <tr>
                                    <td>{this.strings.preswtichDate}</td>
                                    <td><strong>{this.state.selectedEan.preswitch_dtlt !== null ? moment(this.state.selectedEan.preswitch_dtlt, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY') : 'N/A'}</strong></td>
                                  </tr>
                                </tbody>
                              </table>
                              {
                                !(this.props.selectedCompany.role === "partner" && this.state.selectedEan.signatureDate !== null) ?
                                  <button type="button" id="edit" className="btn btn-primary float-right" onClick={this.toggleEdit}><i className="fas fa-edit text-white mr-2" ></i>{this.strings.edit}</button>
                                  : null
                              }
                              {!(this.props.selectedCompany.role === "partner" && this.state.selectedEan.signatureDate !== null) ?
                                <div className="button-container" style={{ display: 'flex', gap: '10px' }}> { }
                                  <button
                                    type="button"
                                    className="btn btn-warning"
                                    disabled={this.state.updatingPreswitch ? 'disabled' : undefined}
                                    onClick={(event) => this.updatePreswitchLight(event)}
                                  >
                                    {this.state.updatingPreswitch ? (
                                      <>
                                        <i className="fas fa-spinner fa-spin mr-2"></i>
                                        {this.strings.preswitchButton}
                                      </>
                                    ) : (
                                      <span>{this.strings.preswitchButton}</span>
                                    )}
                                  </button>
                                  {this.props.selectedCompany.role === 'admin' ?
                                    <button
                                      type="button"
                                      className="btn btn-warning"
                                      disabled={this.state.updatingPreswitchFull ? 'disabled' : undefined}
                                      onClick={(event) => this.updatePreswitchFull(event)}
                                    >
                                      {this.state.updatingPreswitchFull ? (
                                        <>
                                          <i className="fas fa-spinner fa-spin mr-2"></i>
                                          {this.strings.preswitchFulllButton}
                                        </>
                                      ) : (
                                        <span>{this.strings.preswitchFulllButton}</span>
                                      )}
                                    </button>
                                    : null
                                  }

                                  <div>
                                    {this.props.selectedCompany.role === 'admin' ?
                                      <button onClick={this.showRemoveModal} className="btn btn-danger" id="remove">{this.strings.removeButton}</button>
                                      : null
                                    }

                                    {this.state.isModalVisible && (
                                      <div className="modal fade show" id="status-contract" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                                        <div className="modal-dialog" role="document">
                                          <div className="modal-content">
                                            <div className="modal-body">
                                              <p>{`${this.strings.confirmRemoval} sdp with installation_id`} <strong>{this.state.sdpToRemove ? this.state.sdpToRemove.installation_id : ''}</strong>?</p>
                                            </div>
                                            <div className="modal-footer">
                                              <button type="button" className="btn btn-danger" onClick={this.handleConfirm}>{this.strings.confirm}</button>
                                              <button type="button" className="btn btn-secondary" onClick={this.handleCancel}>{this.strings.cancel}</button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}

                                  </div>
                                </div>
                                : null}
                            </div>
                          : null
                      }
                    </form>
                }
                <div>
                  {this.state.infoFull &&
                    <p className='alert alert-info'>
                      <i className="fas fa-info-circle mr-2"></i> {this.state.infoFull}
                    </p>
                  }
                  {this.state.info &&
                    <p className='alert alert-info'>
                      <i className="fas fa-info-circle mr-2"></i> {this.state.info}
                    </p>
                  }
                </div>
                <div className={'alert alert-warning mb-3 ' + (this.state.errors ? 'd-block' : 'd-none')}>
                  <i className="fas fa-exclamation-triangle mr-2"></i> {this.strings.errors}
                  <ul>
                    {
                      this.state.errors ? this.state.errors.sort().map(error => (<li key={error}>{error}</li>)) : null
                    }
                  </ul>
                </div>
              </div>
          }
        </div>
      </div>
    )
  }
}

export default EditSdp;